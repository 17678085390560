/* --
    Typography
-- */

@font-face {
    font-family: 'PoppinsLight';
    src: url( '../../fonts/Poppins-Light.ttf' ) format( 'truetype' );
}

@font-face {
    font-family: 'PoppinsItalic';
    src: url( '../../fonts/Poppins-LightItalic.ttf' ) format( 'truetype' );
}

@font-face {
    font-family: 'PoppinsMedium';
    src: url( '../../fonts/Poppins-Medium.ttf' ) format( 'truetype' );
}

@font-face {
    font-family: 'PoppinsBold';
    src: url( '../../fonts/Poppins-Bold.ttf' ) format( 'truetype' );
}


.font {
    &-body {
        font-family: 'PoppinsLight', sans-serif;

        &-italic {
            font-family: 'PoppinsItalic', sans-serif;
        }

        &-bold {
            font-family: 'PoppinsMedium', sans-serif;
        }
    }

    &-heading {
        font-family: 'PoppinsBold', sans-serif;
    }
}