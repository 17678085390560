/* --
    Global / Root-Document styles
-- */

body {
    background: linear-gradient( 113deg, #262626 0%, #272838 100% );
}

main {
    max-width: 90vw;
    width: 1500px;
    margin: auto;
}

p {
    line-height: 2rem;
}

// .input-wrapper {}

.copy-text-button {
    background: transparent;
    outline: none;
    border: none;
    opacity: 0.5;
    transition: 0.3s;

    svg {
        height: 20px;
        width: 20px;
    }

    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}

.copy-text-notice {
    position: relative;
    z-index: 2;
    transition: 0.3s;
    opacity: 0;
}

.copy-text-container {
    width: 0px;
    height: 0px;
    opacity: 0;
    position: absolute;
    transform: translate( 100%, 100% );
}

.font-size {
    &-1 {
        font-size: 1rem;
    }

    &-2 {
        font-size: 1.5rem;
    }

    &-3 {
        font-size: 2rem;
    }
}

.text-color {
    &-white {
        color: #FFF;
    }

    &-yellow {
        color: $colorYellow;
    }

    &-dark {
        color: #262626;
    }
}

.box-shadow {
    box-shadow: $boxShadow;
}