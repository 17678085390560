/* --
    Footer
-- */

footer {
    nav {
        a {
            text-decoration: none;
            opacity: 0.9;
            transition: 0.3s;

            svg {
                width: 45px;
                height: 45px;
            }

            &:hover {
                opacity: 1;
                transform: scale( 1.05 );
            }
        }
    }
}