/* --
    Getting Started
-- */

section#GettingStarted {
    h2 {
        color: #FFF;
    }

    p {
        @include resolution-md-device {
            width: 100%;
        }
    }
}


.input-wrapper {
    background: $darkRedGradient;
    width: max-content;
    height: max-content;
    border-radius: 10px;

    span {
        color: #FFF;
        background: transparent;

        strong {
            color: $colorRed;
            margin: 0px 5px;
        }
    }
}
