/* --
    Header
-- */

header {
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
}

.header {
    &-logo {
        svg {
            margin-right: 1rem;
        }

        h1 {
            color: #FFF;
            font-size: 2.5rem;
        }
    }

    &-headline {
        color: #FFF;
        font-size: 1.25rem;

        br {
            @include resolution-md-device {
                display: none;
            }
        }
    }
}


.support-button {
    background: #FFF;
    flex: 1;
    text-decoration: none;
    height: max-content;
    display: none;
}