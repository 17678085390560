/* --
    Responsive
-- */

/*
    Usage:
        .my-class {
            @include name-of-mixin {
                property: value;
            }
        }

    --
*/

// Small devices (landscape phones, 576px and up)
@mixin resolution-sm-device {
    @media ( max-width: 576px ) { 
        @content; 
    }
}


// Medium devices (tablets, 768px and up)
@mixin resolution-md-device {
    @media ( max-width: 768px ) { 
        @content; 
    }
}


// Large devices (desktops, 992px and up)
@mixin resolution-lg-device {
    @media ( max-width: 992px ) { 
        @content; 
    }
}


// X-Large devices (large desktops, 1200px and up)
@mixin resolution-xl-device {
    @media ( max-width: 1200px ) { 
        @content; 
    }
}

// XX-Large devices (large desktops, 1200px and up)
@mixin resolution-xxl-device {
    @media ( max-width: 1400px ) { 
        @content; 
    }
}
