/* --
    How It Works
-- */

section#HowItWorks {
    p {
        @include resolution-md-device {
            width: 100%;
        }
    }
}



#example-container {
    display: grid;
    grid-template-columns: repeat( 2, 1fr );

    #example {
        text-align: center;

        @include resolution-md-device {
            margin: 50px auto;
        }
    }

    #example-explainer {
        background: $darkRedGradient;
        border-radius: 10px;
    }

    @include resolution-md-device {
        grid-template-columns: repeat( 1, 1fr );
    }
}
