/* --
    substrat-util styles
*/

@import "substrat-util/index.css";

@import "./base/variables.module.scss";
@import "./base/typography.module.scss";
@import "./base/responsive.module.scss";

@import "./modules/global.module.scss";
@import "./modules/header.module.scss";
@import "./modules/getting-started.module.scss";
@import "./modules/how-it-works.module.scss";
@import "./modules/usage.module.scss";
@import "./modules/footer.module.scss";